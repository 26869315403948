<template>
  <ui-component-modal
    modalSize="large"
    :modalTitle="$t('Components.Events.EventImage.Edit.Main_Title')"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="saveEventImage"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :disableSaveButton="disableSaveButton"
    :buttonLabelConfirm="$t('Components.Events.EventImage.Edit.Button_UploadSave')"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <Message :message="
          $t('Components.Events.EventImage.Edit.Information_FittingImage')
        " />
      <span class="file is-inline-flex is-success has-margin-bottom">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            accept="image/*"
            @change="onFileChange"
          >
          <span class="file-cta">
            <span class="file-icon">
              <font-awesome-icon :icon="['fas', 'image']" />
            </span>
            <span class="file-label">
              {{ $t('Form.Control.ChooseImage') }}
            </span>
          </span>
        </label>
      </span>
      <div class="columns is-mobile is-multiline is-vcentered">
        <div class="column is-7">
          <ImageCropper
            ref="imageCropper"
            :aspectRatio="3 / 2"
            aspectRatioCssClass="is-3by2"
            :guides="false"
            :viewMode="3"
            previewContainer=".imagePreview"
            :originalImage="eventImage"
            @callbackImageChosen="val => { newImageChosen = val }"
            @errorCallbackInvalid="val => { hasImageError = val }"
          />
        </div>
        <div class="column is-5">
          <div class="wrapper-previews">
            <div class="imagePreview has-background-grey-light"></div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations } from 'vuex'
import ImageCropper from '@/components/UI/Components/ImageCropper.vue'
import Message from '@/components/UI/Message'
import uploadProvider from '../../providers/upload'
import eventProvider from '../../providers/event'
import { cleanSource } from '@/utils/objectHelper.js'
export default {
  components: {
    Message,
    ImageCropper
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    onClickCancel: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      eventId: Number(this.$route.params.eventId) || 0,
      eventImage: '',
      newImageChosen: false,
      hasImageError: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      eventData: null
    }
  },

  computed: {
    disableSaveButton() {
      return this.isSaving || !this.newImageChosen || this.hasImageError
    },
  },

  mounted() {
    this.eventData = cleanSource(this.$store.getters['eventStore/getEventData'])
    if (this.eventData !== null) {
      this.eventImage = this.$options.filters.getEventImageSrc(this.eventData.Image)
    }
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    /**
     * Save event image
     * First upload new event image
     * Then save new image to event
     */
    async saveEventImage() {
      if (this.disableSaveButton) { return }
      this.isSaving = true

      try {
        const NEW_IMAGE = this.$refs.imageCropper.getCroppedCanvas()
        let responseImageUpload = await uploadProvider.methods.eventImage(this.eventId, NEW_IMAGE)
        if (responseImageUpload.status === 200) {
          this.eventData.Image = responseImageUpload.data
        }

        let eventResponse = await eventProvider.methods.updateEvent(eventData)
        this.isSavingSuccess = true
        this.setEventData(eventResponse.data)

        let t = setTimeout(() => {
          this.onClickCancel()
          clearTimeout(t)
        }, 1500)
      } catch {
        this.isSavingError = true
        this.isSaving = false
      }
    }
  }
}
</script>


<style lang="sass" scoped>
.wrapper-previews
  display: flex
  flex-grow: 1
  .imagePreview
    overflow: hidden
    aspect-ratio: 4/3
    &:nth-child(1)
      max-width: 80%
      width: 100%
</style>